/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortsInfoFromJSON, PortsInfoToJSON, } from './PortsInfo';
/**
 * Check if a given object implements the Info interface.
 */
export function instanceOfInfo(value) {
    if (!('version' in value) || value['version'] === undefined)
        return false;
    if (!('ports' in value) || value['ports'] === undefined)
        return false;
    if (!('authorizedViaTicket' in value) || value['authorizedViaTicket'] === undefined)
        return false;
    if (!('authorizedViaSsoWithSingleLogout' in value) || value['authorizedViaSsoWithSingleLogout'] === undefined)
        return false;
    return true;
}
export function InfoFromJSON(json) {
    return InfoFromJSONTyped(json, false);
}
export function InfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'version': json['version'],
        'username': json['username'] == null ? undefined : json['username'],
        'selectedTarget': json['selected_target'] == null ? undefined : json['selected_target'],
        'externalHost': json['external_host'] == null ? undefined : json['external_host'],
        'ports': PortsInfoFromJSON(json['ports']),
        'authorizedViaTicket': json['authorized_via_ticket'],
        'authorizedViaSsoWithSingleLogout': json['authorized_via_sso_with_single_logout'],
    };
}
export function InfoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'version': value['version'],
        'username': value['username'],
        'selected_target': value['selectedTarget'],
        'external_host': value['externalHost'],
        'ports': PortsInfoToJSON(value['ports']),
        'authorized_via_ticket': value['authorizedViaTicket'],
        'authorized_via_sso_with_single_logout': value['authorizedViaSsoWithSingleLogout'],
    };
}
