/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SsoProviderKindFromJSON, SsoProviderKindToJSON, } from './SsoProviderKind';
/**
 * Check if a given object implements the SsoProviderDescription interface.
 */
export function instanceOfSsoProviderDescription(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('label' in value) || value['label'] === undefined)
        return false;
    if (!('kind' in value) || value['kind'] === undefined)
        return false;
    return true;
}
export function SsoProviderDescriptionFromJSON(json) {
    return SsoProviderDescriptionFromJSONTyped(json, false);
}
export function SsoProviderDescriptionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'label': json['label'],
        'kind': SsoProviderKindFromJSON(json['kind']),
    };
}
export function SsoProviderDescriptionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'label': value['label'],
        'kind': SsoProviderKindToJSON(value['kind']),
    };
}
