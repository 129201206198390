/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiAuthStateFromJSON, ApiAuthStateToJSON, } from './ApiAuthState';
/**
 * Check if a given object implements the AuthStateResponseInternal interface.
 */
export function instanceOfAuthStateResponseInternal(value) {
    if (!('protocol' in value) || value['protocol'] === undefined)
        return false;
    if (!('started' in value) || value['started'] === undefined)
        return false;
    if (!('state' in value) || value['state'] === undefined)
        return false;
    if (!('identificationString' in value) || value['identificationString'] === undefined)
        return false;
    return true;
}
export function AuthStateResponseInternalFromJSON(json) {
    return AuthStateResponseInternalFromJSONTyped(json, false);
}
export function AuthStateResponseInternalFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'protocol': json['protocol'],
        'address': json['address'] == null ? undefined : json['address'],
        'started': (new Date(json['started'])),
        'state': ApiAuthStateFromJSON(json['state']),
        'identificationString': json['identification_string'],
    };
}
export function AuthStateResponseInternalToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'protocol': value['protocol'],
        'address': value['address'],
        'started': ((value['started']).toISOString()),
        'state': ApiAuthStateToJSON(value['state']),
        'identification_string': value['identificationString'],
    };
}
